<template>
  <div>

    <!-- 上边框 -->
    <van-row type="flex" justify="center" style="margin: 1rem">
      <van-image
          round
          cover
          width="5rem"
          height="5rem"
          :src="require('@/pic/pic.jpg')"
      />
    </van-row>


    <van-form style="margin-bottom: 3rem" validate-trigger="onSubmit" :scroll-to-error="true">

      <van-tabs  :v-model="'myOrder'" @change="changeTabs" @disabled="sorry">

        <van-tab title="我的订单" name="myOrder" >


          <van-list
                  v-model="orderLoading"
                  :error.sync="error"
                  :finished="finished"
                  finished-text="没有更多了"
                  @load="onLoad">

            <van-cell v-for="order in orderList" v-show="order.packageEnteryNumber>0" is-link  :url="`#/orderDetail/${order.orderNo}`">
              <template #title>
                <van-row type="flex" justify="space-between">
                  <van-col >
                    {{order.orderNo}}
                  </van-col>
                  <van-col>{{order.payType}}</van-col>
                </van-row>
              </template>

              <template #label>
                <!-- 两端对齐 -->
                <van-row type="flex" justify="space-between" >
                  <van-col > - {{order.goodsType}} - {{order.transportType}} - {{order.postType}} - {{order.packageEnteryNumber}} 件
                    <br/>- {{order.length}}mm*{{order.width}}mm*{{order.height}}mm*{{order.width}}Kg</van-col>
                  <van-col style="font-size: 13px;color: #ff4444;" v-if="order.payAmount"> ￥{{order.payAmount}}</van-col>
                </van-row>

                <van-row type="flex" justify="space-between" >
                  <van-col > - 体质量:{{order.volumeWeight}}</van-col>
                  <van-col > {{order.createTime}} </van-col>
                </van-row>
              </template>
            </van-cell>
          </van-list>

        </van-tab>





        <van-tab title="截单未打包" name="jiedan" >
          <!-- 可修改文案和加载图标的颜色 -->
<!--          <van-loading v-show="loading" color="#0094ff" />-->
          <van-cell-group title="温馨提示" v-if="truncationCommonPackageList.length>0||truncationSpecialPackageList.length>0">
            <van-cell>
              如果长时间未打包,请和团长确认运单信息是否准确
            </van-cell>
            </van-cell-group>
          <van-loading style="margin-top: 20%"  v-show="loading" type="spinner" size="24px" color="#0094ff"  vertical>加载中...</van-loading>
          <van-cell-group title=""   v-if="!loading" >
            <van-cell title="普货" v-if="truncationCommonPackageList.length>0" style="padding-bottom: 5%">
              <template #title>
                <van-row type="flex" justify="space-between">
                  <van-col >
                    普货({{truncationCommonPackageList.length}}件)
                  </van-col>
                  <van-col>截单日期 {{truncationCommonPackageList[0].truncationDate}}</van-col>
                </van-row>
              </template>

              <template #label>
                <div  v-for="(goods,i) in truncationCommonPackageList">
                  <!-- 两端对齐 -->
                  <van-row type="flex" justify="space-between" style="padding-bottom: 0.5%">
                    <van-col span="19">{{i+1}} -{{goods.goodsName}} </van-col>
                  </van-row>
                  <van-row type="flex" justify="space-between" >
                    <van-col > {{goods.expressNo}}</van-col>
                    <van-col > {{goods.transportType}}-{{goods.postType}}-{{goods.expressType}}</van-col>
                  </van-row>
                  <van-row  type="flex" justify="space-between" >
                    <van-col > {{goods.removePackage}}包装 - {{goods.packageRemark}}</van-col>
                    <van-col > {{goods.expressApplyTime}}</van-col>
                  </van-row>
                  <van-row  type="flex" justify="space-between" >
                    <van-col > {{goods.receiveAddress}}</van-col>
                  </van-row>



                  <van-divider v-if="i<truncationCommonPackageList.length-1"/>
                </div>
              </template>

            </van-cell>
            <van-cell title="敏感" v-if="truncationSpecialPackageList.length>0">
              <template #title>


                <van-row type="flex" justify="space-between">
                  <van-col >
                    敏感({{truncationSpecialPackageList.length}}件)
                  </van-col>
                  <van-col>截单日期 {{truncationSpecialPackageList[0].truncationDate}}</van-col>
                </van-row>
              </template>
              <template #label >
                <div v-for="(sgoods,i)  in truncationSpecialPackageList">

                  <!-- 两端对齐 -->
                  <van-row type="flex" justify="space-between" style="padding-bottom: 0.5%">
                    <van-col span="19">{{i+1}} -{{sgoods.goodsName}} </van-col>
                  </van-row>
                  <van-row type="flex" justify="space-between" >
                    <van-col > {{sgoods.expressNo}}</van-col>
                    <van-col > {{sgoods.transportType}}-{{sgoods.postType}}-{{sgoods.expressType}}</van-col>
                  </van-row>
                  <van-row  type="flex" justify="space-between" >
                    <van-col > {{sgoods.removePackage}}包装 - {{sgoods.packageRemark}}</van-col>
                    <van-col > {{sgoods.expressApplyTime}}</van-col>
                  </van-row>
                  <van-row  type="flex" justify="space-between" >
                    <van-col > {{sgoods.receiveAddress}}</van-col>
                  </van-row>

                  <van-divider v-if="i<truncationSpecialPackageList.length-1"/>

                </div>
              </template>
            </van-cell>
            <van-cell v-if="!(truncationCommonPackageList.length>0||truncationSpecialPackageList.length>0)">
              暂无截单未打包数据
            </van-cell>
            <!--            <van-cell >-->
            <!--            <van-row type="flex" justify="space-around">-->

            <!--            </van-row>-->
            <!--            </van-cell>-->
          </van-cell-group>
        </van-tab>
      </van-tabs>
    </van-form>

    <van-tabbar route :placeholder="true" safe-area-inset-bottom  style="margin-top: 1rem" >
      <van-tabbar-item replace to="/wms" icon="home-o">下单</van-tabbar-item>
      <van-tabbar-item replace to="/order" icon="search"  >我的订单</van-tabbar-item>
    </van-tabbar>

    <!-- 开启底部安全区适配 -->
    <!--    <van-number-keyboard safe-area-inset-bottom />-->

  </div>
</template>

<script>
import {Checkbox, CheckboxGroup, Card, SubmitBar, Toast, Form, Button} from 'vant';

import { queryAlreadyTruncationOrder,queryOrderList } from '@/api/order/order';

export default {
  components: {
    [Card.name]: Card,
    [Checkbox.name]: Checkbox,
    [SubmitBar.name]: SubmitBar,
    [CheckboxGroup.name]: CheckboxGroup,
    [Button.name]: Button,
    [Form.name]: Form
  },

  data() {
    return {
      loading:false,
      submitLoading:false,
      tabsName:'myOrder',
      content: '',
      customerCode:'',
      showPicker: false,
      transportType: '海运',
      postType: '拼邮',
      showPickerPostType:false,
      receiveName: '',
      receiveAddress: '',
      receiveAddressValue: '',
      showReceiveAddressType:false,
      truncationCommonPackageList:[],
      truncationSpecialPackageList:[],
      orderLoading:false,
      orderList:[],
      page:1,
      finished: false,
      error: false,
    };
  },

  computed: {
  },

  methods: {
    changeTabs(name){
      this.tabsName=name
      if(name=='jiedan'){
        this.queryAlreadyTruncationOrder()
      }
    },

    onLoad() {
      const sendParam={
        page:this.page,
        customerCode:this.customerCode
      }
      queryOrderList(sendParam).then(res=>{
        if( res.code='200' ){
          for(let index in res.data.orderList){
            this.orderList.push(res.data.orderList[index])
          }
          if(res.data.orderList.length<20){
            this.finished = true;
          }else{
            this.page=this.page+1;
          }
        }
        // 加载状态结束
        this.orderLoading = false;
      }) .catch(() => {
        this.error = true;
      });
    },
    queryAlreadyTruncationOrder(){
      this.loading=true;
      const sendParam={
        customerCode:this.customerCode
      }
      queryAlreadyTruncationOrder(sendParam).then(res=>{
        this.loading=false;
        this.truncationCommonPackageList=[]
        this.truncationSpecialPackageList=[]
        console.log(res)
        if( res.code='200' ){
          /**
           * 循环分为普货以及敏感
           */
          for(var p in res.data.packageList){
            if(res.data.packageList[p].goodsType=='普货'){
              this.truncationCommonPackageList.push(res.data.packageList[p])

            }else{
              this.truncationSpecialPackageList.push(res.data.packageList[p])
            }
          }

        }
      }) .catch(() => {
        this.loading=false;
        // on cancel
      });
    },

    sorry(){
      Toast({
        message: '撸串去了,过几天在上'
      })
    }

  },
  created() {

    let token = this.$route.query.token
    if(!token||token==''){
      token=JSON.parse(localStorage.getItem("loginToken")|| '{}').loginToken
    }

    console.log('this is token')
    console.log(token)
    if(!token||token==''){
      this.$router.push({ name: 'guide' })
    }else{
      /**
       * 登录 token 保存
       *
       * */
      localStorage.setItem("loginToken",JSON.stringify({loginToken:token}))

      this.customerCode = token
      // this.queryAlreadyTruncationOrder()

    }
  }
};
</script>

<style lang="less">
.card-goods {
  padding: 10px 0;
  background-color: #fff;

  &__item {
    position: relative;
    background-color: #fafafa;

    .van-checkbox__label {
      width: 100%;
      height: auto; // temp
      padding: 0 10px 0 15px;
      box-sizing: border-box;
    }

    .van-checkbox__icon {
      top: 50%;
      left: 10px;
      z-index: 1;
      position: absolute;
      margin-top: -10px;
    }

    .van-card__price {
      color: #f44;
    }
  }
}
</style>
